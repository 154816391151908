<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <dashboard-medal
          :user-data="userData"
          :data="data.congratulations"
        />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <dashboard-statistics :data="data.statisticsItems"/>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <statistic-card-with-area-chart
          v-if="data.paymentReceived"
          icon="PackageIcon"
          color="primary"
          :statistic="data.paymentReceived.totalAmount"
          :chart-data="data.paymentReceived.series"
          :user-data="userData"
        />
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <dashboard-revenue-report
          :data="data.revenue"
          :user-data="userData"
        />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col cols="12">
        <dashboard-company-table
          :user-data="userData"
          :table-data="data.companyTable"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import dashboardStoreModule from '@/views/pages/dashboard/dashboardStoreModule'
import DashboardMedal from '@/views/pages/dashboard/DashboardMedal.vue'
import DashboardStatistics from '@/views/pages/dashboard/DashboardStatistics.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import DashboardCompanyTable from '@/views/pages/dashboard/DashboardCompanyTable.vue'
import DashboardRevenueReport from '@/views/pages/dashboard/DashboardRevenueReport.vue'

export default {
  components: {
    DashboardMedal,
    BRow,
    BCol,
    DashboardStatistics,
    StatisticCardWithAreaChart,
    DashboardRevenueReport,
    DashboardCompanyTable,
  },
  data() {
    return {
      data: {},
      userData: {},
    }
  },
  watch: {
    data: {
      deep: true,
      async handler(data) {
        this.data = data
      },
    },
  },
  beforeMount() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  created() {
    store.dispatch('app-dashboard/fetchDashboard', { id: router.currentRoute.params.id })
      .then(response => {
        this.data = response.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ophalen statistieken',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorDefaultMessage.fetchDataError}`,
          },
        })
      })
  },
  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
