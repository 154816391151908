<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5> {{getGreeting()}} {{ data.name }}</h5>
    <b-card-text
      v-if="userData.type ===2"
    >
      {{userData.companyName}}<br/>
      {{userData.email}}
    </b-card-text>
    <b-card-text
      class="font-small-3"
      v-if="userData.type === 1 && profileData.isVerified === 1"
    >
      Je bent geverifieerd en kan gebruik maken van al onze diensten.
    </b-card-text>
    <b-card-text
      class="font-small-3"
      v-if="userData.type === 1 && (profileData.isVerified === null || profileData.isVerified === 0) && (profileData.idinVerified === null || profileData.idinVerified === 0)"
    >
      Verifieer je identiteit met iDIN
    </b-card-text>
    <b-card-text
      class="font-small-3"
      v-if="userData.type === 1 && profileData.idinVerified === 1 && profileData.idUploaded === 1 && (profileData.idVerified === null || profileData.idVerified === 0)"
    >
      Uw ID upload is in afwachting van goedkeuring.
    </b-card-text>
    <b-card-text
      class="font-small-3"
      v-if="userData.type === 1 && profileData.idinVerified === 1 && (profileData.idUploaded === null || profileData.idUploaded === 0)"
    >
      Upload je paspoort of identiteitsbewijs.
    </b-card-text>
    <b-card-text
      class="font-small-3"
      v-if="userData.type === 1 && profileData.idinVerified === 1 && (profileData.idUploaded === null ||profileData.idUploaded === 0)"
    >
      Upload je paspoort of identiteitsbewijs.
    </b-card-text>
    <b-card-text
      class="font-small-3"
      v-if="userData.type === 1 && profileData.idinVerified === 1 &&  profileData.idVerified === 1 && (profileData.isVerified === null ||profileData.isVerified === 0)"
    >
      Teken de raamovereenkomst, voor je gebruik kan maken van onze diensten.
    </b-card-text>
    <b-button
      v-if="userData.type === 1 && profileData.isVerified && profileData.isVerified === 1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      :to="{name:'app-invoices'}"
    >
      Bekijk uw facturen
    </b-button>
    <b-button
      v-if="userData.type === 1 && (!profileData.isVerified || profileData.isVerified === 0)"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      :to="{name:'app-settings-account'}"
    >
      Verifieer nu
    </b-button>
    <b-img
      v-if="userData.type === 2 || profileData.isVerified && profileData.isVerified === 1"
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import dashboardStoreModule from '@/views/pages/dashboard/dashboardStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getGreeting } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    userData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      profileData: {
        isVerified: null,
      },
    }
  },
  beforeMount() {
    this.getProfile()
  },
  methods: {
    getProfile() {
      store
        .dispatch('app-dashboard-medal/fetchProfile')
        .then(response => {
          this.profileData = response.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen profiel',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    kFormatter,
  },
  setup() {
    const DASHBOARD_MEDAL_APP_STORE_MODULE_NAME = 'app-dashboard-medal'

    // Register module
    if (!store.hasModule(DASHBOARD_MEDAL_APP_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_MEDAL_APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_MEDAL_APP_STORE_MODULE_NAME)) store.unregisterModule(DASHBOARD_MEDAL_APP_STORE_MODULE_NAME)
    })

    return {
      getGreeting,
    }
  },
}
</script>
