<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(companyName)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.company }}
            </div>
            <div class="font-small-3 text-muted">
              {{ data.item.email }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(invoices)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.invoices }} {{ data.item.invoices && data.item.invoices === 1 ? 'factuur':'facturen' }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(AmountOpen)="data">
        &euro; {{ parseFloat(data.item.AmountOpen).toFixed(2) }}
      </template>

      <template #cell(amount)="data">
        &euro; {{ parseFloat(data.item.amount).toFixed(2) }}
      </template>

      <!-- sales -->
      <template #cell(payingTerm)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.payingTerm?data.item.payingTerm + ' dagen':'14 dagen' }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [],
    }
  },
  mounted() {
    if (this.userData.type === 1) {
      this.fields = [
        { key: 'companyName', label: 'OPDRACHTGEVER' },
        { key: 'invoices', label: 'FACTUREN' },
        { key: 'AmountOpen', label: 'OPENSTAAND BEDRAG' },
        { key: 'amount', label: 'UITBETAALD' },
        { key: 'payingTerm', label: 'BETAALTERMIJN' },
      ]
    }

    if (this.userData.type === 2) {
      this.fields = [
        { key: 'freelancer', label: 'FREELANCER' },
        { key: 'invoices', label: 'FACTUREN' },
        { key: 'AmountOpen', label: 'OPENSTAAND BEDRAG' },
        { key: 'amount', label: 'UITBETAALD' },
        { key: 'payingTerm', label: 'BETAALTERMIJN' },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
